import React from 'react'

import { Tournament, Page } from '../lib/createPages'
import { Root, Header } from '../components'
import { Admin } from '../components/admin'
import { WpContextProvider } from '../contexts'

interface AdminTemplateProps {
  pageContext: { tournament: Tournament; edges: { node: Page }[] }
}

const AdminTemplate: React.FC<AdminTemplateProps> = ({ pageContext }) => (
  <WpContextProvider tournament={pageContext.tournament}>
    <Root pageContext={pageContext}>
      <Header pageContext={pageContext}></Header>
      <Admin />
    </Root>
  </WpContextProvider>
)

export default AdminTemplate
